import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import moment from 'moment';
import currencies from '@/assets/currencies.json'

export default {
  namespaced: true,
  state: {
    paymentProviders: [],
    marketingCosts: {},
    productCosts: {},
    secondaryProductCosts: {},
    filters: [],
    notifications: [],
    fixPayments: [],
    transactions: [],
    secondaryCountries: [],
    mainCountry: "",
    lastLogin: null,
    currentDateRange: {},
    currentMultiDateRange: {},
    incompleteData: false,
    recPaymentfees: false,
    recProductcost: false,
    subscriptionLevel: 0,
    freeTrial: true,
    marketingWarning: false,
    reloadData: false,
    storeCurrency: "",
    incomeTax: 0,
    defaultMargin: null,
    profitEmail: "",
    incomeTaxNumber: 0,
    timeZone: "America/New_York",
    currencies: currencies,
    showInitialProductCostImportPopup: false,
    myshopifyUrl: null,
    storeCurrencyObject: {
      Code: "EUR",
      Name: "Euro Member Countries",
      Symbol: '€',
    },
    storeCurrencyObjectMeta: {
      Code: "EUR",
      Name: "Euro Member Countries",
      Symbol: '€',
    },
    meta: false,
    metaAccount: "",
    metaAccounts: [],
    storeRequest: false,
    cjDropshipping: false,
    metaCurrency: null,
    supplierCurrency: "USD",
    manualCurrency: null,
    storeName: "",
    operatingCostDaily: false,
    importTotal: false,
    firstImportOpen: false,
    missingProductCosts: {},
    bundleLoading: false,
    customRevenue: [],
    toHighRevenue: false,
  },
  getters: {
    currentCurrencySymbol: state => state.storeCurrencyObject?.Symbol ?? '€',
    currentCurrencySymbolMeta: state => state.storeCurrencyObjectMeta?.Symbol ?? '€'
  },
  mutations: {
    setshowInitialProductCostImportPopup(state, data) {
      state.showInitialProductCostImportPopup = data ?? false
    },
    setProviders(state, providers) {
      state.paymentProviders = providers ?? [];
    },
    setcustomRevenue(state, customRevenue) {
      state.customRevenue = customRevenue ?? [];
    },
    setbundleLoading(state, data) {
      state.bundleLoading = data ?? false;
    },
    
    setMissingProductCosts(state, costs) {
      state.missingProductCosts = costs ?? {};
    },
    setMeta(state, data) {
      if (data) {
        state.metaAccount = data;
        state.meta = true
      }
    },
    setMetaAccounts(state, data) {

      state.metaAccounts = data ?? [];

    },
    setfilter(state, data) {
      state.filters = data ?? []
    },
    setnotification(state, data) {
      state.notifications = data ?? []
    },
    setStoreName(state, data) {
      state.storeName = data ?? false
    },
    setstoreRequest(state, data) {
      state.storeRequest = data ?? false
    },
    settoHighRevenue(state, data) {
      state.toHighRevenue = data ?? false
    },
    setmetaCurrency(state, data) {
      state.metaCurrency = data ?? null
    },
    setIncompleteData(state, data) {
      state.incompleteData = data ?? false
    },
    setRecPaymentfees(state, data) {
      state.recPaymentfees = data ?? false
    },
    setStoreCurrency(state, data) {
      state.storeCurrency = data ?? ""
    },
    setSecondaryCountries(state, data) {
      state.secondaryCountries = data ?? []
    },
    setMainCountry(state, data) {
      state.mainCountry = data ?? ""
    },
    setSupplierCurrency(state, data) {
      state.supplierCurrency = data ?? "USD"
    },
    setManualCurrency(state, data) {
      state.manualCurrency = data ?? null
    },
    setStoreCurrencyObject(state, data) {
      state.storeCurrencyObject = data ?? {
        Code: "EUR",
        Name: "Euro Member Countries",
        Symbol: '€',
      }
    },
    setStoreCurrencyObjectMeta(state, data) {
      state.storeCurrencyObjectMeta = data ?? {
        Code: "EUR",
        Name: "Euro Member Countries",
        Symbol: '€',
      }
    },
    setincomeTax(state, data) {
      state.incomeTax = data ?? 0
    },
    setTimeZone(state, data) {
      state.timeZone = data ?? "America/New_York"
    },
    setprofitEmail(state, data) {
      state.profitEmail = data ?? ""
    },
    setincomeTaxNumber(state, data) {
      state.incomeTaxNumber = data ?? 0
    },
    setdefaultMargin(state, data) {
      state.defaultMargin = data ?? null
    },
    setRecProductcost(state, data) {
      state.recProductcost = data ?? false
    },
    setReload(state, data) {
      state.reloadData = data ?? false
    },
    setsubscriptionLevel(state, data) {
      console.log("levl set subs")
      state.subscriptionLevel = data ?? 0
    },
    setCj(state, data) {
      state.cjDropshipping = data ?? false
    },
    setimportTotal(state, data) {
      state.importTotal = data ?? false
    },
    setfirstImportOpen(state, data) {
      state.firstImportOpen = data ?? false
    },
    setFreeTrial(state, data) {
      state.freeTrial = data ?? true
    },
    setMarketingWarning(state, data) {
      state.marketingWarning = data ?? false
    },
    setMarketingCost(state, marketing) {
      state.marketingCosts = marketing ?? {};
    },
    setProductCosts(state, productCosts) {
      state.productCosts = productCosts ?? {};
    },
    setProductCostsSecondary(state, productCosts) {
      state.secondaryProductCosts = productCosts ?? {};
    },
    setDateRange(state, currentDateRange) {
      state.currentDateRange = currentDateRange ?? {};
    },
    setDateMultiRange(state, currentDateRange) {
      state.currentMultiDateRange = currentDateRange ?? {};
    },
    updateProductCosts(state, productCost) {
      var key = Object.keys(productCost)[0];
      if (state.productCost == null) {
        state.productCost = {}
      }
      state.productCosts[key] = productCost[key];
    },
    setFixPayments(state, fixPayments) {
      state.fixPayments = fixPayments ?? [];
    },
    setLastLogin(state, lastLogin) {
      state.lastLogin = lastLogin;
    },
    setTransactions(state, transactions) {
      state.transactions = transactions ?? [];
    },
    setOperatingCostDaily(state, value) {
      state.operatingCostDaily = value ?? false;
    },
    setMyshopifyUrl(state, value) {
      state.myshopifyUrl = value ?? null;
    },
  },
  actions: {
    GetPaymentProviders({ commit }) {
      console.log("fetching payment providers from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("payment-providers").doc(firebase.auth().currentUser.uid).get()
          .then(x => {
            resolve();
            commit('setProviders', x.data().providers)

          })
          .catch(e => { reject(e) })
      })
    },
    UpdatePaymentProviders({ commit, state, dispatch }, editrow) {
      var pproviders = JSON.parse(JSON.stringify(state.paymentProviders));
      var index = pproviders.findIndex(e => e.id == editrow.id);
      pproviders[index] = editrow;
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("payment-providers").doc(firebase.auth().currentUser.uid).set({ providers: pproviders })
          .then(_ => {
            dispatch('shopify/CaculationRequired', {}, { root: true })
            commit('setProviders', pproviders)
            resolve();
          })
          .catch(e => { reject(e) });
      })
    },
    InitialProviderSet({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-FetchInitialPaymentNew'
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetLastLogin({ commit, dispatch }) {
      console.log("checking for login")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('userdata').doc(firebase.auth().currentUser.uid).get()
          .then(async x => {
            const dataX = x.data()
            var lastLogin = dataX?.lastLogin ?? null;
            var incompleteData = dataX?.incompleteData ?? true;
            var subscriptionLevel = dataX?.subscriptionLevel ?? 0;
            var freeTrial = dataX?.freeTrial ?? true;
            var marketingWarning = dataX?.marketingWarning ?? false;
            var currency = dataX?.currency ?? ""
            var incomeTax = dataX?.incomeTax ?? 0
            var profitEmail = dataX?.email ?? ""
            var incomeTaxNumber = dataX?.incomeTaxNumber ?? 0
            var timeZone = dataX?.timezone ?? "America/New_York"
            var productCostImportPopUp = dataX?.productCostImportPopUp ?? true
            var secondaryCountries = dataX?.secondaryCountries ?? []
            var mainCountry = dataX?.mainCountry ?? ""
            var margin = dataX?.defaultMargin ?? null;
            var meta = dataX?.metaAccount ?? null;
            var cjDropshipping = dataX?.cjDropshipping ?? false;
            var supplierCurrency = dataX?.supplierCurrency ?? "USD";
            var manualCurrency = dataX?.manualCurrency ?? null;
            var finalOrderFetched = dataX?.finalOrderFetched ?? null
            var storeName = dataX?.storeName ?? firebase.auth().currentUser.uid
            var operatingCostDaily = dataX?.operatingCostDaily ?? false
            var importTotal = dataX?.importTotal ?? false
            var firstImportOpen = dataX?.firstImportOpen ?? true
            var customRev = dataX?.customRevenue ?? false
            var toHighRevenue = dataX?.toHighRevenue ?? false



            console.log("timezone", timeZone)
            dispatch("getStoreRequest", "");
            dispatch('CheckAutomaticAssingment', dataX);
            var cur = await dispatch('SetCurrencySymbol', currency);

            commit("setCj", cjDropshipping)
            commit("settoHighRevenue", toHighRevenue)
            commit("setincomeTax", incomeTax)
            commit("setSecondaryCountries", secondaryCountries)
            commit("setMainCountry", mainCountry)
            commit("setSecondaryCountries", secondaryCountries)
            commit("setTimeZone", timeZone)
            commit("setprofitEmail", profitEmail)
            commit("setincomeTaxNumber", incomeTaxNumber)
            commit("setIncompleteData", incompleteData)
            commit("setsubscriptionLevel", subscriptionLevel)
            commit("setFreeTrial", freeTrial)
            commit("setMarketingWarning", marketingWarning)
            commit("setStoreCurrency", currency)
            commit("setStoreCurrencyObject", cur)
            commit("setimportTotal", importTotal)
            commit("setfirstImportOpen", firstImportOpen)

            commit("setSupplierCurrency", supplierCurrency)
            commit("setManualCurrency", manualCurrency)
            commit("setLastLogin", lastLogin)
            commit("setshowInitialProductCostImportPopup", productCostImportPopUp)
            commit("setdefaultMargin", margin)
            commit("setMeta", meta)
            commit("setOperatingCostDaily", operatingCostDaily)

            commit("setStoreName", storeName)
            commit("setMyshopifyUrl", firebase.auth().currentUser.uid)


            if (meta) {

              dispatch("getMetaAccounts", meta)

            }

            if (subscriptionLevel == 3) {
              dispatch("GetFilters")
            }

            if (customRev) {
              dispatch("getCustomRevenue", "");
            }

            await dispatch('SetLastLogin');

            console.log("check final fetched", finalOrderFetched)
            if (!finalOrderFetched) {
              lastLogin = null;
            }
            resolve(lastLogin);
          })
          .catch(e => { reject(e) })
      })
    },
    async GetFilters({ commit, state }) {


      const docRef = firebase
        .firestore()
        .collection("advancedFilters")
        .doc(firebase.auth().currentUser.uid)
        .collection("filters")

      return new Promise((resolve, reject) => {
        docRef
          .get()
          .then((querySnapshot) => {
            let filter = [];
            querySnapshot.forEach((doc) => {
              filter.push({ id: doc.id, ...doc.data() });
            });
            console.log(filter)
            commit("setfilter", filter);
            resolve();
          })
          .catch((e) => {
            console.log("error fetching filters", e)
            reject(e);
          });
      });
    },
    async Getnotifications({ commit, state }) {


      const docRef = firebase
        .firestore()
        .collection("notifications")
        .doc(firebase.auth().currentUser.uid)
        .collection("notification")

      return new Promise((resolve, reject) => {
        docRef
          .get()
          .then((querySnapshot) => {
            let notification = [];
            querySnapshot.forEach((doc) => {
              notification.push({ id: doc.id, ...doc.data() });
            });

            commit("setnotification", notification);
            resolve();
          })
          .catch((e) => {
            console.log("error fetching notifications", e)
            reject(e);
          });
      });
    },
    async deleteNotification({ commit, dispatch }, id) {
      console.log("delete revenue custom")
      return new Promise((resolve, reject) => {

        firebase.firestore()
          .collection('notifications')
          .doc(firebase.auth().currentUser.uid)
          .collection("notification")
          .doc(id)
          .delete()
          .then(() => {
            resolve();
            // Document deleted successfully
          })
          .catch((error) => {
            reject()
            console.error("Error getting documents: ", error);
          });



        // multiStoreCalc-TestMulti

      })
    },
    SetCurrencySymbol({ state }, currency) {
      var currencyObject = {
        Code: "EUR",
        Name: "Euro Member Countries",
        Symbol: '€',
      }
      try {
        const currencyDetail = state.currencies.find(curr => curr.Code === currency);
        currencyObject = currencyDetail || {
          Code: "EUR",
          Name: "Euro Member Countries",
          Symbol: '€',
        }
      } catch (e) {
        console.log("error defining currency object", e)
      }

      return currencyObject
    },

    async CheckAutomaticAssingment({ state, dispatch }, data) {
      const facebook = data?.facebook ?? false
      const pinterest = data?.pinterest ?? false
      const google = data?.google ?? false
      const snapchat = data?.snapchat ?? false
      const tiktok = data?.tiktok ?? false
      const cj = data?.cjDropshipping ?? false
      const currentDateRaw = moment();
      const currentDate = currentDateRaw.subtract(1, 'days');

      if (cj == true) {
        console.log("start automatic cj assignment")
        try {
          dispatch('api/apiPostWithToken', {
            path: '/userdata-CalculateDailyProductCostAutomaticNew', body: {}
          }, { root: true }).then(x => {

          }).catch(error => {
            reject(error);
          });
        } catch (e) {
          console.log("error automatic cjdropshipping assignment", e, firebase.auth().currentUser.uid)
        }

      }

      if (pinterest == true) {
        const lastPinterestAssignment = data?.lastPinterestAssignment ?? null
        if (lastPinterestAssignment) {
          const date = moment(lastPinterestAssignment);
          // Compare if the parsed date is before today
          if (date.isBefore(currentDate, 'day')) {
            try {
              var pinterestRes = await dispatch('AutoAssignPinterest', "{}");
            } catch (e) {
              console.log("error auto Assign pinterest object", e)
            }
          }
        }
      }

      if (snapchat == true) {
        const lastSnapChatAssignment = data?.lastSnapChatAssignment ?? null
        if (lastSnapChatAssignment) {
          const date = moment(lastSnapChatAssignment);
          // Compare if the parsed date is before today
          if (date.isBefore(currentDate, 'day')) {
            try {
              var snapchatRes = await dispatch('AutoAssignSnapChat', "{}");
            } catch (e) {
              console.log("error auto Assign snapchat object", e)
            }
          }
        }
      }

      if (tiktok == true) {
        const lastTikTokAssignment = data?.lastTikTokAssignment ?? null
        if (lastTikTokAssignment) {
          const date = moment(lastTikTokAssignment);
          // Compare if the parsed date is before today
          if (date.isBefore(currentDate, 'day')) {
            try {
              var snapchatRes = await dispatch('AutoAssignTiktok', "{}");
            } catch (e) {
              console.log("error auto Assign tikotk object", e)
            }
          }
        }
      }

      if (facebook == true) {
        const lastFacebookAssignment = data?.lastFacebookAssignment ?? null
        if (lastFacebookAssignment) {
          const date = moment(lastFacebookAssignment);
          // Compare if the parsed date is before today
          if (date.isBefore(currentDate, 'day')) {
            try {
              var snapchatRes = await dispatch('AutoAssignFacebook', "{}");
            } catch (e) {
              console.log("error auto Assign facebook object", e)
            }
          }
        }

      }

      return ""
    },
    SetUserDataGeneral({ commit }, userDataG) {
      commit("setincomeTax", userDataG.incomeTax)
      commit("setprofitEmail", userDataG.email)
      commit("setincomeTaxNumber", userDataG.incomeTaxNumber)
    },
    SetDefaultMarginGeneral({ commit }, margin) {
      commit("setdefaultMargin", margin)
    },
    SetStoreName({ commit }, name) {
      commit("setStoreName", name)
    },
    SetLastLogin({ commit }) {
      return firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({ lastLogin: moment().format() }, { merge: true })
    },
    SetSupplierCurrency({ commit }, currency) {

      commit("setSupplierCurrency", currency)
      return firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({ supplierCurrency: currency }, { merge: true })
    },
    SetManualCurrency({ commit }, currency) {

      commit("setManualCurrency", currency)
      return firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({ manualCurrency: currency }, { merge: true })
    },
    
    getMetaAccounts({ commit, dispatch }, meta) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-getMetaStores', body: {
            data: meta
          }
        }, { root: true }).then(x => {

          commit("setMetaAccounts", x.data?.resArray)
          commit("setmetaCurrency", x.data?.metaCurrency ?? null)
          dispatch('SetCurrencySymbol', x.data?.metaCurrency).then(xd => {
            commit("setStoreCurrencyObjectMeta", xd)
          });

          console.log("respnse meta", x.data)
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })

    },
    ChangeUser({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-changeUser', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    SetProductPopUp({ commit }) {
      commit("setshowInitialProductCostImportPopup", false)
      return firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({ productCostImportPopUp: false }, { merge: true })
    },
    RequestBilling({ dispatch }, discount) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/billing-RequestBilling',
          body: { shopName: shopName, freeTrial: this.state.freeTrial, affiliateCode: discount.affiliateCode, discount: discount.value, plan: discount.plan }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    confirmBilling({ dispatch }, chargeID) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/billing-checkBilling',
          body: { shopName: shopName, chargeID: chargeID }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    cancelBilling({ dispatch }) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/billing-cancelBilling',
          body: { shopName: shopName }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    checkDiscount({ dispatch }, code) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/billing-discountCheck',
          body: { shopName: shopName, code: code }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    // Cost per product
    GetProductCosts({ dispatch, commit }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/userdata-GetProductCostAdditionals',

        }, { root: true }).then(x => {
          let data = x.data
          console.log(data)
          commit("setProductCosts", data)
          resolve(data);

          resolve(data)
        }).catch(error => {
          reject(error);
        });
      })
      // return new Promise((resolve, reject) => {


      //   GetProductCostAdditionals
      //   firebase.firestore().collection("product-additionals").doc(firebase.auth().currentUser.uid).get()
      //     .then(x => {
      //       let data = x.data();
      //       commit("setProductCosts", data)
      //       resolve(data);
      //     })
      //     .catch(e => { reject(e) })
      // })
    },

    GetProductCostsSecondary({ commit }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("product-additionals").doc(firebase.auth().currentUser.uid).collection("secondaryCountries").doc("secondaryCountries").get()
          .then(x => {
            let data = x.data();
            commit("setProductCostsSecondary", data)
            resolve(data);
          })
          .catch(e => { reject(e) })
      })
    },

    GetProductCostsCustomBundle({ commit }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("product-additionals").doc(firebase.auth().currentUser.uid).collection("customBundle").doc("customBundle").get()
          .then(x => {
            let data = x.data();
            resolve(data);
          })
          .catch(e => { reject(e) })
      })
    },

    GetProductDigital({ commit }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("product-additionals").doc(firebase.auth().currentUser.uid).collection("digitalProducts").doc("digitalProducts").get()
          .then(x => {
            let data = x.data();
            resolve(data);
          })
          .catch(e => { reject(e) })
      })
    },

    GetFacebookCampaigns({ dispatch }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        console.log('Get Facebook Campagins --')
        dispatch('api/apiPostWithToken', {
          path: '/facebook-GetCampaigns', body: {}
        }, { root: true }).then(x => {
          console.log("x", x.data)
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });
    },
    SetFacebookCampaigns({ dispatch }, data) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        console.log('Set Facebook Campagins --')
        dispatch('api/apiPostWithToken', {
          path: '/facebook-SetCampaigns', body: data
        }, { root: true }).then(x => {
          console.log("x", x.data)
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });
    },
    DeleteFacebookCampaigns({ dispatch }, data) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        console.log('Delet Facebook Campagins --')
        dispatch('api/apiPostWithToken', {
          path: '/facebook-DeleteCampaigns', body: data
        }, { root: true }).then(x => {
          console.log("x", x.data)
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });
    },


    GetMissingProductCosts({ commit }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("product-additionals").doc(firebase.auth().currentUser.uid).collection("secondaryCountries").doc("missingProductCost").get()
          .then(x => {
            let data = x.data();
            commit("setMissingProductCosts", data)
            resolve(data);
          })
          .catch(e => { reject(e) })
      })
    },
    SetDateRange({ commit }, data) {
      commit("setDateRange", data)
    },
    SetDateMultiRange({ commit }, data) {
      commit("setDateMultiRange", data)
    },
    SetreloadData({ commit }, data) {
      commit("setReload", data)
    },


    SetIncompleteData({ commit }, data) {
      commit("setIncompleteData", data)
    },
    SetRecPaymentfees({ commit }, data) {
      commit("setRecPaymentfees", data)

      setTimeout(() => {
        // your new function here
        commit("setRecPaymentfees", false)
        commit("setReload", true)
      }, 4 * 60 * 1000);  // 4 mi


    },
    SetrecProductcost({ commit }, data) {
      commit("setRecProductcost", data)

      setTimeout(() => {
        // your new function here
        commit("setRecProductcost", false)
        commit("setReload", true)
      }, 4 * 61 * 1000);  // 4 mi


    },
    // Cost per product
    GetProductCostsForProduct({ }, ids) {
      console.log("fetching targeted product costs for specific product from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('product').doc(firebase.auth().currentUser.uid).collection(ids.productid.toString()).doc('product').get()
          .then(x => {
            var product = x.data();
            resolve(product.variants.find(x => x.id == ids.variantid)?.productCost)
          })
          .catch(e => { reject(e) })
      })
    },

    // Requires this object:
    //{
    //    id: 45007419572508, (variant id)
    //    cost1: 80, (these three could be omitted, but you have to have at least 1 cost defined)
    //    cost2: 90,
    //    cost3: 40,
    //    pid: 8236031213852, (product id)
    //    initial: true,
    //    startDate: '2023-04-28T18:31:07+02:00'
    //    endDate: '2023-06-28T18:31:07+02:00' (Optional, if hidden it will count as the current productcost)
    //  }
    async UpdateProductCost({ dispatch, commit }, productCost) {
      return new Promise((resolve, reject) => {
        console.log('setting productcosts')
        dispatch('api/apiPostWithToken', {
          path: '/userdata-SetProductCost', body: productCost
        }, { root: true }).then(x => {
          resolve()
        }).catch(error => {
          reject(error);
        });
      });
    },
    async UpdateProductCostBulk({ dispatch, commit }, productCost) {
      return new Promise((resolve, reject) => {
        console.log('setting productcosts')
        dispatch('api/apiPostWithToken', {
          path: '/userdata-SetProductCostBulk', body: productCost
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });
    },

    async DeleteProductCost({ dispatch, commit }, productCost) {
      return new Promise((resolve, reject) => {
        console.log('deleting productcosts')
        dispatch('api/apiPostWithToken', {
          path: '/userdata-DeleteProductCost', body: productCost
        }, { root: true }).then(x => {
          resolve()
        }).catch(error => {
          reject(error);
        });
      });
    },

    async ProductCostCalculation({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        console.log('calculating product cost')
        dispatch('api/apiPostWithToken', {
          path: '/userdata-CalculateNewProductCostNew',
        }, { root: true }).then(x => {
          resolve()
        }).catch(error => {
          reject(error);
        });
      });
    },

    async FetchNewBundlesInitial({ dispatch, commit }, data) {
      let shopName = firebase.auth().currentUser.uid
     
      console.log("test the new bundle log")
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FetchFirstTimeBundleAnalytics', body: { shopName: shopName }
        }, { root: true })
        resolve("started")
      })
    },

    async ImportProductCostCalculation({ dispatch, commit }, orderData) {
      return new Promise((resolve, reject) => {
        console.log('importing productcosts preparation')
        dispatch('api/apiPostWithToken', {
          path: '/userdata-ImportProductCostCalculationsNew', body: orderData
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });
    },

    async ConfirmImportProductCost({ dispatch, commit }, productCosts) {
      return new Promise((resolve, reject) => {
        console.log('confirmImprt')

        dispatch('api/apiPostWithToken', {
          path: '/userdata-CalculateNewProductCostAutomaticNew', body: productCosts
        }, { root: true }).then(x => {
        }).catch(error => {
          reject(error);
        });

        dispatch('api/apiPostWithToken', {
          path: '/userdata-ConfirmProductCostImport', body: productCosts
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });
    },
    async productCostMarginChangeAll({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        console.log('recalulate all product cost')

        dispatch('api/apiPostWithToken', {
          path: '/userdata-CalculateNewProductCostAutomaticNew', body: { all: true }
        }, { root: true }).then(x => {
        }).catch(error => {
          reject(error);
        });


      });
    },
    async ConnectPinterest({ dispatch, commit }, mode) {

      try {
        const response = await dispatch('api/apiGetWithToken', { path: '/pinterest-RequestPinterestIntegration' }, { root: true });
        const authUrl = response.data.authUrl;


        const authWindow = window.open(authUrl, 'pinterest-auth', 'width=500,height=500');
        var authCode = "";
        await new Promise((resolve, reject) => {
          const checkUrlChange = setInterval(() => {
            if (authWindow.closed) {
              clearInterval(checkUrlChange);
              console.log("Authentication window closed");
              reject(new Error("Authentication window closed"));
              return;
            }

            if (authWindow.location.href !== authUrl && authWindow.location.href != "about:blank") {
              clearInterval(checkUrlChange);
              const newUrl = authWindow.location.href;
              console.log("URL changed to:", newUrl);
              authCode = newUrl.substring(newUrl.indexOf('=') + 1);
              console.log("Authorization code:", authCode);
              resolve();
            }
          }, 1000); // Check every 1 second
        });

        if (authCode != null) {
          authWindow.close();
          var modeData = { authCode: authCode, mode: mode }
          const data = await dispatch('GetPinterestTokens', modeData);
          return data;
        } else {
          console.log("error getting Authcode");
          authWindow.close();
          return null;
        }

      } catch (error) {
        console.error('Error:', error.response.status, error.response.statusText);
        throw error;
      }

    },
    async ConnectPinterestTwo({ dispatch, commit }, mode) {

      try {
        const response = await dispatch('api/apiGetWithToken', { path: '/pinterest-RequestPinterestIntegration' }, { root: true });
        const authUrl = response.data.authUrl;


        const authWindow = window.open(authUrl, 'pinterest-auth', 'width=500,height=500');
        var authCode = "";
        await new Promise((resolve, reject) => {
          const checkUrlChange = setInterval(() => {
            if (authWindow.closed) {
              clearInterval(checkUrlChange);
              console.log("Authentication window closed");
              reject(new Error("Authentication window closed"));
              return;
            }

            if (authWindow.location.href !== authUrl && authWindow.location.href != "about:blank") {
              clearInterval(checkUrlChange);
              const newUrl = authWindow.location.href;
              console.log("URL changed to:", newUrl);
              authCode = newUrl.substring(newUrl.indexOf('=') + 1);
              console.log("Authorization code:", authCode);
              resolve();
            }
          }, 1000); // Check every 1 second
        });

        if (authCode != null) {
          authWindow.close();
          var modeData = { authCode: authCode, mode: mode }
          const data = await dispatch('GetPinterestTokensTwo', modeData);
          return data;
        } else {
          console.log("error getting Authcode");
          authWindow.close();
          return null;
        }

      } catch (error) {
        console.error('Error:', error.response.status, error.response.statusText);
        throw error;
      }

    },

    async ConnectFacebook({ dispatch, commit }) {

      try {
        console.log("Facebook start")
        const response = await dispatch('api/apiGetWithToken', { path: '/facebook-authenticateWithFacebook' }, { root: true });
        const authUrl = response.data;

        console.log(response)
        const authWindow = window.open(authUrl, 'facebook-auth', 'width=500,height=500');
        var authCode = "";
        await new Promise((resolve, reject) => {
          const checkUrlChange = setInterval(() => {
            if (authWindow.closed) {
              clearInterval(checkUrlChange);
              console.log("Authentication window closed");
              reject(new Error("Authentication window closed"));
              return;
            }

            if (authWindow.location.href !== authUrl && authWindow.location.href != "about:blank") {
              clearInterval(checkUrlChange);
              const newUrl = authWindow.location.href;
              console.log("URL changed to:", newUrl);
              authCode = newUrl.substring(newUrl.indexOf('=') + 1);
              console.log("Authorization code:", authCode);
              resolve();
            }
          }, 1000); // Check every 1 second
        });

        if (authCode != null) {
          authWindow.close();
          const data = await dispatch('GetFacebookTokens', authCode);
          return data;
        } else {
          console.log("error getting Authcode");
          authWindow.close();
          return null;
        }

      } catch (error) {
        console.error('Error:', error.response.status, error.response.statusText);
        throw error;
      }

    },
    async getFacebookAccounts({ dispatch }) {

      return new Promise((resolve, reject) => {
        console.log('get facebook accounts')
        dispatch('api/apiPostWithToken', {
          path: '/facebook-getAccounts',
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      });

    },

    async ConnectSnapchat({ dispatch, commit }) {

      try {
        console.log("Snapchat start")
        const response = await dispatch('api/apiGetWithToken', { path: '/snapchat-authenticateWithSnapchat' }, { root: true });
        const authUrl = response.data;
        var authCode = "";

        console.log(response)
        const authWindow = window.open(authUrl, 'snapchat-auth', 'width=500,height=500');

        await new Promise((resolve, reject) => {
          const checkUrlChange = setInterval(() => {
            if (authWindow.closed) {
              clearInterval(checkUrlChange);
              console.log("Authentication window closed");
              reject(new Error("Authentication window closed"));
              return;
            }

            if (authWindow.location.href !== authUrl && authWindow.location.href != "about:blank") {
              clearInterval(checkUrlChange);
              const newUrl = authWindow.location.href;
              console.log("URL changed to:", newUrl);
              authCode = newUrl.substring(newUrl.indexOf('=') + 1);
              console.log("Authorization code:", authCode);
              resolve();
            }
          }, 1000); // Check every 1 second
        });
        console.log("Now access Snapchat API")
        if (authCode != null) {
          authWindow.close();
          const data = await dispatch('GetSnapchatTokens', authCode);
          return data;
        } else {
          console.log("error getting Authcode");
          authWindow.close();
          return null;
        }

      } catch (error) {
        console.error('Error:', error.response.status, error.response.statusText);
        throw error;
      }

    },

    async ConnectGoogle({ dispatch, commit }) {

      try {
        console.log("Google start")
        const response = await dispatch('api/apiGetWithToken', { path: '/google-authenticateWithGoogle' }, { root: true });
        console.log("okay - should be fine by now")
        console.log(response)
        const authUrl = response.data;
        var authCode = "";


        const authWindow = window.open(authUrl, 'google-auth', 'width=500,height=500');

        await new Promise((resolve, reject) => {
          const checkUrlChange = setInterval(() => {
            if (authWindow.closed) {
              clearInterval(checkUrlChange);
              console.log("Authentication window closed");
              reject(new Error("Authentication window closed"));
              return;
            }

            if (authWindow.location.href !== authUrl && authWindow.location.href != "about:blank") {
              clearInterval(checkUrlChange);
              const newUrl = authWindow.location.href;
              console.log("URL changed to:", newUrl);
              authCode = newUrl.substring(newUrl.indexOf('=') + 1);
              console.log("Authorization code:", authCode);
              resolve();
            }
          }, 1000); // Check every 1 second
        });
        console.log("Now access Google API")
        if (authCode != null) {
          authWindow.close();
          const data = await dispatch('GetGoogleTokens', authCode);
          console.log("check google", data)
          return data;
        } else {
          console.log("error getting access token");
          authWindow.close();
          return null;
        }

      } catch (error) {
        console.error('Error:', error.response.status, error.response.statusText);
        throw error;
      }

    },

    async ConnectTiktok({ dispatch, commit }) {

      try {
        console.log("Tiktok start")
        const response = await dispatch('api/apiGetWithToken', { path: '/tiktok-authenticateWithTiktok' }, { root: true });
        console.log("okay - should be fine by now")
        console.log(response)
        const authUrl = response.data;
        var authCode = "";


        const authWindow = window.open(authUrl, 'tiktok-auth', 'width=500,height=500');

        await new Promise((resolve, reject) => {
          const checkUrlChange = setInterval(() => {
            if (authWindow.closed) {
              clearInterval(checkUrlChange);
              console.log("Authentication window closed");
              reject(new Error("Authentication window closed"));
              return;
            }


            if (authWindow.location.href !== authUrl && authWindow.location.href != "about:blank") {
              clearInterval(checkUrlChange);
              const newUrl = authWindow.location.href;
              console.log("URL changed to:", newUrl);
              authCode = newUrl.split('=')[1].split('&')[0];
              // authCode = newUrl.substring(newUrl.indexOf('=') + 1);

              console.log("Authorization code:", authCode);
              resolve();
            }
          }, 1000); // Check every 1 second
        });

        if (authCode != null) {
          authWindow.close();

          const data = await dispatch('GetTiktokTokens', authCode);
          console.log("check tiktok", data)
          return data;
        } else {
          console.log("error getting access token");
          authWindow.close();
          return null;
        }

      } catch (error) {
        console.error('Error:', error.response.status, error.response.statusText);
        throw error;
      }

    },


    GetFacebookTokens({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/facebook-facebookCallback', body: {
            authCode: data,
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    GetPinterestTokens({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/pinterest-GetPinterestToken', body: {
            data,
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetPinterestTokensTwo({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/pinterest-GetPinterestTokenTwo', body: {
            data,
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    GetSnapchatTokens({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/snapchat-snapchatCallback', body: {
            authCode: data,
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetGoogleTokens({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/google-googleCallback', body: {
            authCode: data,
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetTiktokTokens({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/tiktok-tiktokCallback', body: {
            authCode: data,
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    SelectPinterestAdAccount({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/pinterest-SelectedPinterestAdAccount', body: {
            adAccountID: data.adAccountID,
            currency: data.currency,
            mode: data?.mode ?? "",
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    SelectPinterestAdAccountTwo({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/pinterest-SelectedPinterestAdAccountTwo', body: {
            adAccountID: data.adAccountID,
            currency: data.currency,
            mode: data?.mode ?? "",
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    SelectGoogleAdAccount({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/google-SelectGoogleAdAccountNew', body: {
            adAccountID: data.adAccountID,
            mode: data?.mode ?? "",
            parent: data?.parent ?? null,
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    SelectTiktokAdAccount({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/tiktok-SelectTiktokAdAccount', body: {
            adAccountID: data.adAccountID,
            mode: data?.mode ?? "",
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    SelectFacebookAdAccount({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/facebook-SelectFacebookAdAccountNew', body: {
            adAccountID: data.adAccountID,
            adAccountID2: data.adAccountID2,
            mode: data?.mode ?? "",
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    SelectAdditionalFacebookAdAccount({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/facebook-SelectFacebookAdAccountNewAdditional', body: {
            adAccountID: data.adAccountID,
            adAccountID2: data.adAccountID2,
            mode: data?.mode ?? "",
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    SelectSnapchatAdAccount({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/snapchat-SelectSnapchatAdAccount', body: data
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    confirmChangeMainStore({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/billing-changeMainStore', body: {
            newMetaAccount: data,
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    saveCJ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/cjDropshipping-saveCJ', body: data
        }, { root: true }).then(x => {

          if (x.data == "success") {
            commit("setCj", true)


            dispatch('api/apiPostWithToken', {
              path: '/cjDropshipping-firstImport', body: {}
            }, { root: true }).then(x => {

            }).catch(error => {
              reject(error);
            });


            dispatch('api/apiPostWithToken', {
              path: '/userdata-CalculateNewProductCostAutomaticNew', body: {}
            }, { root: true }).then(x => {

            }).catch(error => {
              reject(error);
            });
          }

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    saveCJforConnectedStore({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/cjDropshipping-saveCJforConnectedStore', body: data
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },


    deactivateCJ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/cjDropshipping-deactivateCJ', body: data
        }, { root: true }).then(x => {
          commit("setCj", false)
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    AutoAssignPinterest({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/pinterest-AutoAssignNewAds', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    AutoAssignSnapChat({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/snapchat-AutoAssignNewAds', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    AutoAssignTiktok({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/tiktok-AutoAssignNewAds', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    AutoAssignFacebook({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/facebook-AutoAssignNewAdsNew', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    TestPinterest({ commit, dispatch }, data) {
      console.log("Test is send")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/admin-ResetStore', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },

    ReloadStoreMonth({ commit, dispatch }, data) {
      console.log("Test is send")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/admin-ResetStoreMonth', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    
    TestOrders({ commit, dispatch }, data) {
      console.log("Test is send")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/admin-TestOrders', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    TestOrders({ commit, dispatch }, data) {
      console.log("Test is send")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/admin-TestTotalNumbers', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    TestDesiredTesting({ commit, dispatch }, data) {
      console.log("Test Desired is send")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/admin-TestDesiredBehavior', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    activateWebhookDeinstall({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/billing-addWebhookDeinstall', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    activateCrossVariant({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-SetCrossVariantCosts', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    saveCustomRevenue({ commit, dispatch }, data) {
      console.log("save custom revneue")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/admin-saveCustomRevenue', body: data
        }, { root: true }).then(x => {
          resolve()
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    getCustomRevenue({ commit, dispatch }) {
      console.log("fetching custom revenue")
      return new Promise((resolve, reject) => {
        firebase.firestore()
          .collection('custom-revenue')
          .doc(firebase.auth().currentUser.uid)
          .collection("revenue")
          .get()
          .then((querySnapshot) => {
            let documentsArray = [];
            querySnapshot.forEach((doc) => {
              documentsArray.push({
                id: doc.id,
                ...doc.data()
              });
            });

            commit('setcustomRevenue', documentsArray)
            resolve(documentsArray);
            // Now you can use documentsArray as needed
          })
          .catch((error) => {
            console.error("Error getting documents: ", error);
          });


      })
    },
    deleteRevenue({ commit, dispatch }, id) {
      console.log("delete revenue custom")
      return new Promise((resolve, reject) => {

        firebase.firestore()
          .collection('custom-revenue')
          .doc(firebase.auth().currentUser.uid)
          .collection("revenue")
          .doc(id)
          .delete()
          .then(() => {
            resolve();
            // Document deleted successfully
          })
          .catch((error) => {

            console.error("Error getting documents: ", error);
          });



        // multiStoreCalc-TestMulti

      })
    },
    deactivateCrossVariant({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-DeCrossVariantCosts', body: {}
        }, { root: true }).then(x => {

        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    changeProductImportTotal({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        firebase.firestore().collection('userdata').doc(firebase.auth().currentUser.uid)
          .update({ importTotal: data }) // Use update() instead of set()
          .then(() => {
            commit("setimportTotal", data)
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    finalOnboardingImport({ commit, dispatch }, data) {
      console.log("-d-", data)
      commit("setfirstImportOpen", data)
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('userdata').doc(firebase.auth().currentUser.uid)
          .update({ firstImportOpen: data }) // Use update() instead of set()
          .then(() => {

            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });



    },




    getLiveData({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-GetLiveData', body: {}
        }, { root: true }).then(x => {

          console.log("live x", x)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    getLiveDataLastHour({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-GetLiveDataForCurrentHour', body: {}
        }, { root: true }).then(x => {

          console.log("live x", x)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    getLiveDataMulti({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-GetLiveDataMulti', body: data
        }, { root: true }).then(x => {

          console.log("live x", x)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    getLiveDataMultiLastHour({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-GetLiveDataMultiHourly', body: data
        }, { root: true }).then(x => {

          console.log("live x", x)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    getAttributedMarketing({ commit, dispatch }, data) {
      console.log("ge mar")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-GetAttributionData', body: data
        }, { root: true }).then(x => {

          console.log("live x", x)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    getImportedBundles({ commit, dispatch }, data) {
      console.log("ge mar")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-GetImportedBundles', body: data
        }, { root: true }).then(x => {

          console.log("imported bundles", x)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },


    setCustomBundleCost({ commit, dispatch }, data) {
      console.log("set custom cost")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-customBundleCost', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },

    setDigital({ commit, dispatch }, data) {
      console.log("set custom cost")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-customDigital', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },
    setTag({ commit, dispatch }, data) {
      console.log("set tag")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/shopify-addRefundTag', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },

    deleteTag({ commit, dispatch }, data) {
      console.log("delete digital")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/shopify-deleteRefundTag', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },


    GetTags({ commit }) {
      console.log("fetching product costs from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('userdata').doc(firebase.auth().currentUser.uid).get()
          .then(x => {
            let data = x.data();
            let tags = data?.refundTags ?? {}
            resolve(tags);
          })
          .catch(e => { reject(e) })
      })
    },

    deleteCustomBundleCost({ commit, dispatch }, data) {
      console.log("set custom cost")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-deleteCustomBundleCost', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },

    deleteImportedBundle({ commit, dispatch }, data) {
      console.log("set custom cost")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-deleteImportedBundle', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },

    deleteDigital({ commit, dispatch }, data) {
      console.log("delete digital")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/userdata-deleteDigital', body: { data: data }
        }, { root: true }).then(x => {
          resolve("success")
        }).catch(error => {
          reject(error);
        });

        // multiStoreCalc-TestMulti

      })
    },

    setMultiStoreCurrency({ commit, dispatch }, data) {
      console.log("set multi store currency")
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/multiStoreCalc-SetMulitStoreCurrency', body: { data }
        }, { root: true }).then(x => {
          console.log("d", x)

          if (x.data == "success") {
            commit("setmetaCurrency", data.currency ?? null)
          }
          resolve(x.data);
        }).catch(error => {
          reject(error);
        });

      })
    },

    firstCJImport({ commit, dispatch }, data) {
      console.log("cjImport")
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/cjDropshipping-firstImport', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    SendStoreRequest({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-sendStoreRequest', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    getStoreRequest({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-getStoreRequest', body: {
            data: data
          }
        }, { root: true }).then(x => {

          if (x.data) {
            commit('setstoreRequest', true)
          }

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    AcceptStoreRequest({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/multiStoreCalc-acceptStoreRequest', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    requestCredentials({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-GenerateCustomLogin', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    changeStoreName({ state, commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/multiStoreCalc-changeStoreName', body: {
            data: data
          }
        }, { root: true }).then(x => {


          const metaAccounts = state.metaAccounts;

          // Specify the specific URL you are looking for
          const specificUrl = firebase.auth().currentUser.uid

          // Find the object with the specific URL
          const foundObject = metaAccounts.find(account => account.url === specificUrl);

          // Check if the object was found
          if (foundObject) {
            // Change the value of the object
            foundObject.storeName = data.storeName // Replace with the property you want to change

            // Update the state
            // Assuming you have a mutation named 'updateMetaAccounts'

          }

          commit("setMetaAccounts", metaAccounts)




          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    removeAccount({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/multiStoreCalc-removeAccount', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },



    DenyStoreRequest({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-denyStoreRequest', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },



    SendHelpRequest({ commit, dispatch }, data) {

      console.log("data .", data)
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-HelpRequest', body: {
            data: data
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    // Cost per product
    GetMarketingCosts({ commit }) {
      console.log("fetching marketing costs from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("product-marketing").doc(firebase.auth().currentUser.uid).get()
          .then(x => {
            commit("setMarketingCost", x.data())
            resolve(x.data());
          })
          .catch(e => { reject(e) })
      })
    },
    // Everything about FixPayments
    async SetRecurringFixPayment({ commit }, fixedPayment) {
      const docRef = firebase.firestore().collection('fix-payments').doc(firebase.auth().currentUser.uid);

      // Get the current fix payments array
      const currentFixPaymentsDoc = await docRef.get();

      // If the array does not exist, create it
      let currentFixPayments = [];
      if (currentFixPaymentsDoc.exists && currentFixPaymentsDoc.data().recurringPayments) {
        currentFixPayments = currentFixPaymentsDoc.data().recurringPayments;
      }

      // Add the new fixedPayment to the array
      currentFixPayments.push(fixedPayment);

      // Save the updated array to Firestore
      return new Promise((resolve, reject) => {
        docRef.set({ recurringPayments: currentFixPayments })
          .then(_ => {
            commit("setFixPayments", currentFixPayments)
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },


    async GetRecurringFixPayments({ commit }) {
      console.log("fetching fixpayments costs from firebase...");
      const docRef = firebase.firestore().collection("fix-payments").doc(firebase.auth().currentUser.uid);

      return new Promise((resolve, reject) => {
        docRef.get()
          .then(docSnapshot => {
            if (docSnapshot.exists) {
              const data = docSnapshot.data();
              if (data.recurringPayments) {
                commit("setFixPayments", data.recurringPayments)
                resolve(data.recurringPayments);
              } else {
                resolve([]);
              }
            } else {
              resolve([]);
            }
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    // Inside your store.js actions
    async DeleteRecurringFixPayment({ state, dispatch }, indexToDelete) {
      const updatedFixedPayments = state.fixPayments.filter((_, index) => index !== indexToDelete);

      return new Promise((resolve, reject) => {
        firebase.firestore().collection('fix-payments').doc(firebase.auth().currentUser.uid)
          .update({ recurringPayments: updatedFixedPayments }) // Use update() instead of set()
          .then(() => {
            dispatch('GetRecurringFixPayments');
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    async UpdateRecurringFixPayment({ state, dispatch }, data) {
      const newAmount = data.payment.amount;
      const endDate = data.payment.endDate;  // New amount value
      const indexToUpdate = data.index;       // Index to update

      // Create a copy of the fixPayments array and update the specific payment's amount
      const updatedFixedPayments = state.fixPayments.map((payment, index) => {
        if (index === indexToUpdate) {
          return {
            ...payment,
            amount: newAmount,
            endDate: endDate,
          };
        }
        return payment;
      });

      return new Promise((resolve, reject) => {
        firebase.firestore().collection('fix-payments').doc(firebase.auth().currentUser.uid)
          .update({ recurringPayments: updatedFixedPayments }) // Use update() instead of set()
          .then(() => {
            dispatch('GetRecurringFixPayments');
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    async SetTransaction({ dispatch, commit, state }, transaction) {

      return new Promise((resolve, reject) => {


        dispatch('api/apiPostWithToken', {
          path: '/operatingExpenses-SaveSingleTransaction', body: {
            data: transaction,
          }
        }, { root: true }).then(x => {

          if (x.data == "-") {
            resolve()
            return;
          }
          const currentTransaction = { ...x.data, id: x.data.id };
          commit("setTransactions", [...state.transactions, currentTransaction]);


          if (state.metaAccount) {
            dispatch('api/apiPostWithToken', {
              path: '/multiStoreCalc-CheckRecalcMutliStoreOperating', body: {
                transaction: currentTransaction,
                action: "add",
                metaAccount: state.metaAccount

              }
            }, { root: true }).then(x => {

            }).catch(error => {
              reject(error);
            });
          }
          resolve();

        }).catch(error => {
          reject(error);
        });
      })
    },
    async UpdateTransaction({ dispatch, commit, state }, transaction) {

      return new Promise((resolve, reject) => {

        if (state.metaAccount) {
          dispatch('api/apiPostWithToken', {
            path: '/multiStoreCalc-CheckRecalcMutliStoreOperating', body: {
              transaction: transaction,
              action: "update",
              metaAccount: state.metaAccount

            }
          }, { root: true }).then(x => {

          }).catch(error => {
            reject(error);
          });
        }

        dispatch('api/apiPostWithToken', {
          path: '/operatingExpenses-UpdateSingleTransaction', body: {
            data: transaction,
          }
        }, { root: true }).then(x => {
          var data = x.data
          const updatedTransactionIndex = state.transactions.findIndex(transaction => transaction.id === data.id);

          if (updatedTransactionIndex !== -1) {
            const updatedTransactions = [...state.transactions];
            updatedTransactions[updatedTransactionIndex] = data;
            commit("setTransactions", updatedTransactions);
          } else {
            const currentTransaction = { ...data, id: data.id };
            commit("setTransactions", [...state.transactions, currentTransaction]);
          }
          resolve("success");

        }).catch(error => {
          reject(error);
        });
      })
    },

    async GetTransaction({ commit, state }, { start, end }) {

      // The following complicated structure is somehow the only way to make sure diffrent timzones get handled correctly
      const formatDate = (date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
      };

      const startString = formatDate(start);
      const endString = formatDate(end);

      var timeZone = "America/New_York"
      if (state.timeZone == "") {
        timeZone = "America/New_York"
      } else {
        timeZone = state.timeZone;
      }

      const adjustedStartDate = moment.tz(startString, 'MM/DD/YYYY', timeZone).startOf('day').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
      const adjustedEndDate = moment.tz(endString, 'MM/DD/YYYY', timeZone).endOf('day').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');

      const startAsDate = new Date(adjustedStartDate);
      const endAsDate = new Date(adjustedEndDate);


      const docRef = firebase
        .firestore()
        .collection("fix-payments")
        .doc(firebase.auth().currentUser.uid)
        .collection("transactions")
        .where("date", ">=", startAsDate)
        .where("date", "<=", endAsDate)
        .orderBy("date");

      return new Promise((resolve, reject) => {
        docRef
          .get()
          .then((querySnapshot) => {
            let transactions = [];
            querySnapshot.forEach((doc) => {
              transactions.push({ id: doc.id, ...doc.data() });
            });
            transactions.sort((a, b) => b.date.toDate() - a.date.toDate());
            commit("setTransactions", transactions);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    async SetDayliOperating({ dispatch, commit, state }) {
      const docRef = firebase.firestore().collection('userdata').doc(firebase.auth().currentUser.uid)

      return new Promise((resolve, reject) => {
        docRef.set({ operatingCostDaily: true }, { merge: true })
          .then(doc => {
            commit("setOperatingCostDaily", true)

            dispatch('api/apiPostWithToken', {
              path: '/multiStoreCalc-RecalculateMultiOperating', body: {
                metaAccount: state.metaAccount
              }
            }, { root: true }).then(x => {
              resolve();
            }).catch(error => {
              console.log(error)
              resolve();
            });


          })
          .catch(e => {
            reject(e);
          });
      });
    },
    async deactivateDayliOperating({ dispatch, commit, state }) {
      const docRef = firebase.firestore().collection('userdata').doc(firebase.auth().currentUser.uid)

      return new Promise((resolve, reject) => {
        docRef.set({ operatingCostDaily: false }, { merge: true })
          .then(doc => {
            commit("setOperatingCostDaily", false)

            dispatch('api/apiPostWithToken', {
              path: '/multiStoreCalc-RecalculateMultiOperating', body: {
                metaAccount: state.metaAccount
              }
            }, { root: true }).then(x => {
              resolve();
            }).catch(error => {
              console.log(error)
              resolve();
            });

          })
          .catch(e => {
            reject(e);
          });
      });
    },


    async DeleteTransaction({ dispatch, commit, state }, transactionId) {
      const docRef = firebase.firestore().collection('fix-payments').doc(firebase.auth().currentUser.uid).collection('transactions').doc(transactionId);

      return new Promise((resolve, reject) => {

        const updatedTransactions = state.transactions.filter(transaction => transaction.id !== transactionId);
        commit("setTransactions", updatedTransactions);

        if (state.metaAccount) {
          dispatch('api/apiPostWithToken', {
            path: '/multiStoreCalc-CheckRecalcMutliStoreOperating', body: {
              transaction: { id: transactionId },
              action: "delete",
              metaAccount: state.metaAccount

            }
          }, { root: true }).then(x => {
            docRef.delete()
              .then(() => {

                resolve();
              })
              .catch(e => {
                reject(e);
              });
          }).catch(error => {
            reject(error);
          });
        } else {
          docRef.delete()
            .then(() => {

              resolve();
            })
            .catch(e => {
              reject(e);
            });
        }


      });
    }

  },

}