<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="17pt" height="17pt" viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">

 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
<path d="M2015 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -982 -3 -2144 l3
-2112 21 -27 c11 -15 33 -37 48 -48 27 -21 38 -21 566 -21 528 0 539 0 566 21
15 11 37 33 48 48 l21 27 0 2139 0 2139 -21 27 c-11 15 -33 37 -48 48 -27 21
-40 21 -554 23 -423 2 -533 0 -557 -11z"/>
<path d="M3615 3506 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -694 -3 -1504 3
-1468 3 -1472 24 -1499 11 -15 33 -37 48 -48 27 -21 38 -21 566 -21 528 0 539
0 566 21 15 11 37 33 48 48 21 27 21 28 21 1526 0 1498 0 1499 -21 1526 -11
15 -33 37 -48 48 -27 21 -40 21 -554 23 -423 2 -533 0 -557 -11z"/>
<path d="M415 2226 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -406 -3 -864 3 -822
3 -832 24 -859 11 -15 33 -37 48 -48 27 -21 38 -21 566 -21 528 0 539 0 566
21 15 11 37 33 48 48 21 27 21 34 21 886 0 852 0 859 -21 886 -11 15 -33 37
-48 48 -27 21 -40 21 -554 23 -423 2 -533 0 -557 -11z"/>
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'BarChartIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  