<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15pt" height="18pt" viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
<path d="M2390 4953 c-104 -7 -212 -25 -247 -39 -113 -47 -170 -137 -183 -291
-5 -54 -17 -123 -26 -153 -72 -244 -284 -413 -536 -428 -115 -6 -191 9 -309
64 -53 25 -119 47 -147 51 -64 7 -140 -11 -196 -48 -80 -54 -231 -275 -343
-504 -96 -196 -108 -251 -78 -352 21 -72 64 -125 147 -182 131 -89 213 -192
260 -330 33 -95 33 -267 0 -362 -47 -137 -129 -241 -259 -330 -82 -56 -116
-96 -144 -169 -37 -98 -24 -164 74 -365 76 -155 125 -236 223 -372 82 -112
134 -154 215 -173 74 -18 139 -6 248 44 118 55 194 70 309 64 264 -16 476
-194 542 -454 11 -43 20 -101 20 -129 0 -147 87 -264 224 -299 192 -50 682
-41 801 15 113 53 162 133 175 286 16 173 69 299 171 402 111 111 238 169 391
179 115 6 191 -9 309 -64 53 -25 119 -47 147 -51 64 -7 140 11 196 48 80 54
231 275 343 504 96 196 108 251 78 352 -21 72 -64 125 -147 182 -131 89 -213
192 -260 330 -33 95 -33 267 0 362 47 138 129 241 260 330 83 57 126 110 147
182 30 101 18 156 -78 352 -76 155 -125 236 -223 372 -82 112 -134 154 -215
173 -74 18 -139 6 -248 -44 -118 -55 -194 -70 -309 -64 -263 16 -476 194 -542
454 -11 43 -20 101 -20 129 0 149 -88 265 -227 300 -112 28 -368 41 -543 28z
m371 -1603 c201 -51 372 -175 484 -350 89 -140 128 -271 128 -435 0 -142 -21
-233 -83 -360 -87 -178 -198 -289 -375 -375 -133 -65 -212 -83 -355 -83 -382
1 -698 250 -795 628 -24 95 -25 275 0 370 49 194 161 364 311 471 207 148 446
194 685 134z"/>
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'SettingsIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
}

.icon-wrapper svg {
    padding: 0;
    margin: 0;
    display: block;
}

.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  