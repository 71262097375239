<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="17pt" height="28pt" viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">

 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
<path d="M2465 4646 c-48 -12 -1819 -746 -1813 -751 2 -2 167 -71 367 -155
311 -130 367 -150 390 -142 55 20 1826 793 1834 801 10 10 -516 229 -593 248
-67 16 -119 15 -185 -1z"/>
<path d="M2835 3870 c-451 -197 -869 -380 -929 -406 -79 -34 -104 -49 -93 -55
8 -5 180 -79 381 -164 l366 -155 927 407 c511 223 931 409 935 412 8 7 -741
322 -758 320 -5 -1 -378 -162 -829 -359z"/>
<path d="M3695 3241 l-970 -428 -3 -1156 c-1 -681 2 -1157 7 -1157 23 0 1841
764 1875 788 22 15 50 47 63 71 l23 44 0 1134 c0 900 -3 1133 -12 1132 -7 -1
-449 -193 -983 -428z"/>
<path d="M430 2523 c0 -1114 0 -1117 21 -1160 12 -24 36 -55 54 -69 28 -21
1864 -794 1887 -794 4 0 7 520 6 1156 l-3 1156 -435 185 c-334 142 -1215 512
-1497 629 l-33 13 0 -1116z"/>
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'CubeIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  