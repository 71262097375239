<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15pt" height="18pt" viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
<path d="M2974 4981 c-101 -25 -84 -8 -1455 -1380 -1479 -1478 -1376 -1365
-1386 -1521 -7 -90 13 -170 57 -240 36 -55 1611 -1629 1660 -1658 98 -57 238
-66 355 -22 58 21 103 65 1378 1339 725 724 1331 1336 1347 1361 62 98 61 72
58 996 l-3 839 -31 65 c-41 86 -108 153 -192 193 l-67 32 -845 2 c-465 0 -859
-2 -876 -6z m952 -884 c107 -54 178 -169 177 -288 -1 -103 -24 -136 -237 -348
-158 -156 -203 -196 -252 -218 -76 -35 -157 -36 -232 -3 -70 30 -154 121 -177
191 -22 67 -16 158 15 219 25 50 338 373 408 422 65 45 109 58 182 54 45 -2
81 -11 116 -29z"/>
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'BundleAnalyticsIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
}

.icon-wrapper svg {
    padding: 0;
    margin: 0;
    display: block;
}

.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  