<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="17pt" height="17pt" viewBox="0 0 512 512"
             preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="#1D2752" stroke="none">
                <path d="M4225 5085 c-240 -53 -435 -230 -516 -470 -21 -60 -24 -89 -24 -205
                0 -149 17 -222 76 -328 l30 -53 -382 -392 c-210 -216 -392 -402 -404 -415
                l-21 -23 -67 45 c-37 25 -97 59 -135 77 -37 17 -68 33 -70 34 -3 3 71 291 113
                440 l14 50 76 12 c289 46 491 339 431 623 -23 110 -68 194 -145 271 -213 213
                -539 213 -752 0 -168 -169 -202 -409 -89 -631 22 -42 138 -168 172 -186 16 -9
                12 -28 -42 -233 -33 -123 -63 -233 -66 -245 -5 -20 -9 -21 -130 -17 -202 5
                -373 -29 -547 -111 -52 -25 -91 -38 -96 -32 -19 21 -431 574 -431 579 0 3 15
                24 32 48 46 59 96 172 113 253 77 363 -145 723 -501 814 -94 24 -241 26 -334
                4 -224 -52 -418 -232 -496 -459 -25 -71 -28 -94 -28 -210 0 -114 3 -140 27
                -210 86 -260 299 -437 571 -477 99 -14 211 -2 312 32 47 16 60 18 70 8 7 -7
                107 -139 222 -292 l209 -279 -71 -83 c-297 -345 -374 -814 -203 -1239 l34 -84
                -150 -100 -149 -99 -61 39 c-87 56 -172 81 -282 81 -182 1 -344 -83 -441 -229
                -144 -217 -117 -492 65 -674 287 -287 768 -162 885 231 19 61 22 215 7 268
                l-10 33 150 100 150 100 85 -80 c178 -169 390 -276 629 -318 67 -11 130 -14
                245 -10 183 6 296 31 450 100 l97 44 115 -156 114 -156 -28 -45 c-48 -75 -70
                -148 -75 -245 -9 -157 36 -281 143 -395 248 -262 674 -210 849 105 167 299 3
                683 -330 771 -66 18 -210 18 -269 0 l-47 -14 -98 133 c-55 73 -107 144 -116
                159 l-17 26 59 63 c94 101 186 252 238 390 10 26 20 47 23 47 3 0 78 -14 167
                -31 l162 -31 7 -60 c23 -219 175 -433 381 -533 271 -133 577 -82 790 130 363
                363 216 981 -271 1141 -80 26 -102 29 -219 29 -147 0 -217 -17 -335 -82 -85
                -47 -216 -178 -263 -264 l-37 -66 -162 32 -163 31 -6 114 c-14 246 -82 452
                -214 644 l-46 68 238 244 c131 135 312 322 403 415 l165 170 60 -31 c130 -65
                300 -86 450 -55 39 8 113 36 165 61 77 38 110 62 176 129 68 67 90 98 127 176
                125 258 92 535 -90 757 -149 182 -433 279 -668 227z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'CustomIcon'
};
</script>

<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
