<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="17pt" height="16pt" viewBox="0 0 512 512"
 preserveAspectRatio="xMidYMid meet">

 <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
<path d="M2405 5059 c-647 -61 -1216 -514 -1415 -1127 -75 -233 -73 -205 -79
-1027 l-6 -740 -210 -418 -210 -419 0 -86 c0 -68 5 -97 22 -134 29 -63 96
-134 158 -166 l50 -27 1845 0 1845 0 50 27 c62 32 129 103 158 166 17 37 22
66 22 134 l0 86 -210 419 -210 418 -6 740 c-5 714 -6 743 -27 837 -99 437
-331 786 -690 1037 -90 62 -304 169 -413 204 -111 37 -248 64 -379 76 -128 12
-165 12 -295 0z"/>
<path d="M1635 653 c43 -102 139 -236 230 -322 395 -369 995 -369 1390 0 91
86 187 220 230 322 l15 37 -940 0 -940 0 15 -37z"/>
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'CubeIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  