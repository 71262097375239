import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import moment from 'moment';
import userData from './user-data';

export default {
  namespaced: true,
  state: {
    perProductInfo: {},
    perMultiData: {},
    bundleData: {},

  },
  getters: {
  },
  mutations: {
    setCalculationData(state, data) {
      state.perProductInfo = data;
    },
    setBundleData(state, data) {
      state.bundleData = data;
    },
    setMultiData(state, data) {
      state.perMultiData = data;
    },
    updateSingleMarketing(state, obj) {
      var previous = state.perProductInfo.perProductInfo[obj.id].marketing;
      var profitchange = previous - obj.data;
      state.perProductInfo.perProductInfo[obj.id].marketing = obj.data
      state.perProductInfo.perProductInfo[obj.id].profit += profitchange
    }
  },
  actions: {
    CalculateOrderProductCombinations({ commit, dispatch }, data) {
      const fromDate = moment(data.from).format(); // Use your desired format here
      const toDate = moment(data.to).endOf('day').format(); // Use your desired format here


      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-PerformCalculations', body: {
            from: fromDate,
            to: toDate
          }
        }, { root: true }).then(x => {
          commit("setCalculationData", x.data)

          dispatch("shopify/FetchAnalyticsProductName", [], { root: true }).then(b => {


            const producNamesFetched = b

            console.log("Product Name fetch ", producNamesFetched)
            if (!producNamesFetched) {

              try {
                const keysArray = Object.keys(x.data?.perProductInfo?.products);
                if (keysArray) {
                  dispatch("shopify/FetchTargetedProductsFromDatabase", keysArray, { root: true });
                }
              } catch (e) {
                console.log("might be no products", e)
              }
            }

          });


          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    CalculateBundles({ commit, dispatch }, data) {
      const fromDate = moment(data.from).format(); // Use your desired format here
      const toDate = moment(data.to).endOf('day').format(); // Use your desired format here


      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/bundleAnalytics-PerformBundleCalculation', body: {
            from: fromDate,
            to: toDate
          }
        }, { root: true }).then(x => {

          commit("setBundleData", x.data)
          dispatch("shopify/FetchAnalyticsProductName", [], { root: true }).then(b => {


            const producNamesFetched = b

            console.log("Product Name fetch ", producNamesFetched)
            if (!producNamesFetched) {

              try {
                const keysArray = Object.keys(x.data?.perProductInfo?.products);
                if (keysArray) {
                  dispatch("shopify/FetchTargetedProductsFromDatabase", keysArray, { root: true });
                }
              } catch (e) {
                console.log("might be no products", e)
              }
            }

          });


          resolve("success")
        }).catch(error => {
          reject(error);
        });
      })
    },
    getAdvancedProducts({ commit, dispatch }, data) {
      const fromDate = moment(data.from).format(); // Use your desired format here
      const toDate = moment(data.to).endOf('day').format(); // Use your desired format here
      const filter = data?.filter ?? null

      console.log("advanced filter fetch")

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/advancedFiltering-fetchFilterData', body: {
            from: fromDate,
            to: toDate,
            filter: filter
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        })
      })
    },
    getDataforExport({ commit, dispatch }, data) {
      const fromDate = moment(data.from).format(); // Use your desired format here
      const toDate = moment(data.to).endOf('day').format(); // Use your desired format here


      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/shopify-PerformCalculations', body: {
            from: fromDate,
            to: toDate
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    CalculateMultiDashboard({ commit, dispatch }, data) {
      const fromDate = moment(data.from).format(); // Use your desired format here
      const toDate = moment(data.to).endOf('day').format(); // Use your desired format here
      const metaAccount = data.metaAccount


      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/multiStoreCalc-PerformMultiCalculations', body: {
            from: fromDate,
            to: toDate,
            metaAccount: metaAccount
          }
        }, { root: true }).then(x => {
          commit("setMultiData", x.data)

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },


    ComputeMarketingCostForProduct({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {

        dispatch('api/apiPostWithToken', {
          path: '/shopify-computeMarketingCostForProduct', body: {
            from: data.from,
            to: data.to,
            productMarketing: data.productMarketing
          }
        }, { root: true }).then(x => {

          var obj = { id: data.id, data: x.data }
          commit("updateSingleMarketing", obj)
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetAssignedAds({ commit, dispatch }, data) {

      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/generalMarketing-GetAssignedAds', body: {
            id: data.id,
            platform: data.platform
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetUnassignedAds({ commit, dispatch }, platform) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/generalMarketing-GetUnassignedAds', body: {
            platform: platform
          }
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    RemoveAssignedAds({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/generalMarketing-RemovingAssignedAds', body: {
            assigned: data.assigned,
            adId: data.adId,
            docId: data.docId,
            platform: data.platform
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },

    AssignRemovedAds({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/generalMarketing-AssignedRemovedAds', body: {
            assigned: data.assigned,
            adId: data.adId,
            docId: data.docId,
            productID: data.productID,
            platform: data.platform
          }
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },


    async createFirstFilter({ dispatch }, chargeID) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/advancedFiltering-createFirstFilterUltimate',
          body: { shopName: shopName }
        }, { root: true })
        resolve("success")
      })
    },


    createFilter({ dispatch }, filter) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/advancedFiltering-createNewFilter',
          body: { shopName: shopName, filter: filter }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },

    deleteFilter({ dispatch }, filter) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/advancedFiltering-deleteFilter',
          body: { shopName: shopName, filter: filter }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    createnotification({ dispatch }, notification) {
      let shopName = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/liveTracking-createNotification',
          body: { shopName: shopName, notification: notification }
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
  },
}
